/* requires:
polyfill.js
*/

// for active menu highlighting
(function () {
  const a = document.getElementById('nav').getElementsByTagName('a');
  // let loc;
  // if (window.location.href.substr(location.href.length - 1, 1) == '/') {
  //   loc = window.location.href + 'index.html';
  // } else {
  //   loc = window.location.href;
  // }
  let loc = window.location.href;
  for (var i = 0; i < a.length; i++) {
    if (a[i].href == loc) {
      a[i].classList.add('is-active');
    }
  }
})();

document
  .getElementById('nav')
  .querySelector('ul')
  .insertAdjacentHTML(
    'beforebegin',
    "<span data-bs-toggle='offcanvas' href='#offcanvas' role='button' aria-controls='offcanvas' aria-label='Menu'><span id='menutoggle'><div class='hamburger-box'><div class='hamburger-inner'></div></div><span>Menu</span></span></span>"
  );

const menuToggle = document.getElementById('menutoggle');
const subMenuToggle = document.getElementById('menutoggle');
const activeClass = 'is-active';

menuToggle.onclick = function (event) {
  event.preventDefault();
};

for (let submenuToggle of document.querySelectorAll('.sidenav span.submenu, #nav span.submenu, #nav a.submenu')) {
  submenuToggle.addEventListener('click', function () {
    submenuToggle.classList.toggle(activeClass);
  });
}
